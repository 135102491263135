<template lang="pug">
.command-container
  .flex.center.command-header
    h1.command-header-title Ejecutar comando
  b-loading(:is-full-page='true' :active.sync='loading')
  .flex-column(v-if='!loading')
    .flex-column.command-content(v-if='showAction')
        span.title.text-center {{ actionText }}
        .wrap.command-buttons
          b-button(outlined @click='onCancel') Cancelar
          b-button.ml-3(outlined type='is-primary' @click='onSendCommand') {{ actionBtnLabel }}
    .flex-column.command-content(v-else='!showAction')
      span.text-center.mb-5 {{ info }}
      span.count-down.mb-5 {{ timerCount }}
      b-button(outlined @click='onCancel') Cancelar
</template>

<script>
import { mapActions, mapState } from 'vuex'
import router from '@/router'
import { commandTypeDescriptions } from '@/constants/enums'
import devicesService from '@/services/devices.service'

const COUNT_DOWN_DELAY = 1000
const TIMER_COUNT = 3

export default {
  props: {
    commandId: { type: String, default: null },
    deviceId: { type: String, default: null },
    isModal: { type: Boolean, default: false }
  },
  data () {
    return {
      params: { deviceId: null, commandName: '', commandId: '' },
      device: null,
      isLoading: false,
      showAction: false,
      timerCount: TIMER_COUNT
    }
  },
  created () {
    this.getParams()
    this.getDevice()
  },
  computed: {
    ...mapState({
      savingLoading: state => state.commands.savingLoading
    }),
    loading () {
      return this.isLoading || this.savingLoading
    },
    info () {
      return `Te dispones a ${this.params.commandName} vehículo ${this.device?.name}, por favor espera...`
    },
    actionText () {
      return `¿${this.params.commandName} vehículo ${this.device?.name} ahora?`
    },
    actionBtnLabel () {
      return `${this.params.commandName} ahora`
    }
  },
  methods: {
    ...mapActions({
      saveCommand: 'commands/save'
    }),
    getParams () {
      const { commandId, deviceId } = this.$route?.params
      this.params = {
        deviceId: this.deviceId ? Number(this.deviceId) : Number(deviceId),
        commandName: commandTypeDescriptions[this.commandId ?? commandId] || '',
        commandId: this.commandId ?? commandId
      }
    },
    async getDevice () {
      this.isLoading = true
      const response = await devicesService.getOne(this.params.deviceId)
      this.isLoading = false
      this.countDown()
      this.device = response || {}
    },
    countDown () {
      this.intervalId = setInterval(() => {
        this.timerCount--
        if (this.timerCount === 0) {
          clearInterval(this.intervalId)
          this.showAction = true
        }
      }, COUNT_DOWN_DELAY)
    },
    onCancel () {
      this.isModal ? this.$emit('close') : router.go(-1)
    },
    onSendCommand () {
      const { commandId: type, commandName: name, deviceId } = this.params
      const command = { type, deviceId: `${deviceId}`, parameters: '', name }
      this.saveCommand(command)
      this.onCancel()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.command-container {
  background: $white;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 250px;
  min-width: 450px;
}

.command-header {
  background: $main-color;
  padding: 8px 5px;
}

.command-header-title {
  color: $white;
  font-size: 20px;
  font-weight: 700;
}

.command-content {
  align-items: center;
  justify-content: start;
  padding: 40px 20px 20px;
}

.count-down {
  font-size: 60px;
}
</style>
